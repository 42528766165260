<template>
  <Page :title="'搜索'">
    <div class="seach_bg">
      <div class="input-group">
        <div class=" w-50 m-auto search">
          <div class="tabs">
            <div class="tab" @click="indextabfun(1)" :class="{ active: indextab == '1' }">资讯</div>
            <div class="tab"  @click="indextabfun(2)" :class="{ active: indextab == '2' }">机构</div>
            <div class="tab"  @click="indextabfun(3)" :class="{ active: indextab == '3' }">人员</div>
            <div class="tab "  @click="indextabfun(4)" :class="{ active: indextab == '4' }">案例</div>
            <div class="tab"  @click="indextabfun(5)" :class="{ active: indextab == '5' }">政策法规 </div>
          </div>
          <div class="search-box">
            <div class="el-icon-search search-icon"></div>
            <a-input type="text" @keyup.enter="search" :value="keyword"  v-model="keyword" placeholder="请输入关键字搜索"/>
            <span @click="search">搜 索</span></div>
        </div>
      </div>
    </div>
    <article id="page-seach">
      <section class="container container-main search-result">
         <Collection
            :name="modeldata"
            :query="{ post_title : query.post_title, provider_name : query.provider_name, person_name : query.person_name, recase_title : query.recase_title, law_title : query.law_title}"
            v-slot="{ collection, count }"
          >
        <div class="search-lest mt-4">
          <div class="alert alert-success" role="alert">
            为您搜索到结果 {{count}} 个
          </div>
          <div class="el-table el-table--fit el-table--border el-table--enable-row-transition" style="width: 100%;">
            <div class="hidden-columns">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="el-table__header-wrapper">
              <table cellspacing="0" cellpadding="0" border="0" class="el-table__header">
                <colgroup>
                  <col name="el-table_1_column_1" width="800">
                  <col name="el-table_1_column_2" width="270">
                  <col name="el-table_1_column_3" width="100">
                  <col name="gutter" width="0">
                </colgroup>
                <thead class="has-gutter">
                <tr class="">
                  <th colspan="1" rowspan="1" class="el-table_1_column_1 is-leaf bg_f3"><div class="cell">标题</div></th>
                  <th colspan="1" rowspan="1" class="el-table_1_column_2 is-center bg_f3"><div class="cell">类型</div></th>
                  <th colspan="1" rowspan="1" class="el-table_1_column_3 is-center bg_f3"><div class="cell">#</div></th>
                </tr>
                </thead>
              </table>
            </div>
            <div class="el-table__body-wrapper is-scrolling-none">
              <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
                <colgroup>
                  <col name="el-table_1_column_1" width="800">
                  <col name="el-table_1_column_2" width="270">
                  <col name="el-table_1_column_3" width="100">
                </colgroup>
                <tbody v-if="indextab == '1'">
                <tr class="el-table__row" v-for="value in collection" :key="value.post_id">
                  <td rowspan="1" colspan="1" class="el-table_1_column_1  "><div class="cell">{{value.post_title}}</div></td>
                  <td rowspan="1" colspan="1" class="el-table_1_column_2 is-center"><div class="cell">{{value.target_name}}</div></td>
                  <td rowspan="1" colspan="1" class="el-table_1_column_3 is-center"><div class="cell"><router-link :to="`/article/detail/${value.post_id}`">[详情]</router-link></div></td>
                </tr>
                </tbody>
                  <tbody v-if="indextab == '2'">
                <tr class="el-table__row" v-for="value in collection" :key="value.provider_id">
                  <td rowspan="1" colspan="1" class="el-table_1_column_1  "><div class="cell">{{value.provider_name}}</div></td>
                  <td rowspan="1" colspan="1" class="el-table_1_column_2 is-center"><div class="cell">{{value.provider_type}}</div></td>
                  <td rowspan="1" colspan="1" class="el-table_1_column_3 is-center"><div class="cell"><router-link :to="`/orgdetails/${value.provider_id}`">[详情]</router-link></div></td>
                </tr>
                </tbody>
                 <tbody v-if="indextab == '3'">
                <tr class="el-table__row" v-for="value in collection" :key="value.person_id">
                  <td rowspan="1" colspan="1" class="el-table_1_column_1  "><div class="cell">{{value.person_name}}</div></td>
                  <td rowspan="1" colspan="1" class="el-table_1_column_2 is-center"><div class="cell">{{value.provider_name}}</div></td>
                  <td rowspan="1" colspan="1" class="el-table_1_column_3 is-center"><div class="cell"><router-link :to="`/personDetails/${value.person_id}`">[详情]</router-link></div></td>
                </tr>
                </tbody>
                <tbody v-if="indextab == '4'">
                <tr class="el-table__row" v-for="value in collection" :key="value.recase_id">
                  <td rowspan="1" colspan="1" class="el-table_1_column_1  "><div class="cell">{{value.recase_title}}</div></td>
                  <td rowspan="1" colspan="1" class="el-table_1_column_2 is-center"><div class="cell">{{value.recase_keyword}}</div></td>
                  <td rowspan="1" colspan="1" class="el-table_1_column_3 is-center"><div class="cell"><router-link :to="`/case/detail/${value.recase_id}`">[详情]</router-link></div></td>
                </tr>
                </tbody>
                 <tbody v-if="indextab == '5'">
                <tr class="el-table__row" v-for="value in collection" :key="value.law_id">
                  <td rowspan="1" colspan="1" class="el-table_1_column_1  "><div class="cell">{{value.law_title}}</div></td>
                  <td rowspan="1" colspan="1" class="el-table_1_column_2 is-center"><div class="cell">{{value.law_ctype}}</div></td>
                  <td rowspan="1" colspan="1" class="el-table_1_column_3 is-center"><div class="cell"><router-link :to="`/law_res/detail/${value.law_id}`">[详情]</router-link></div></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="tabs_pagination">
            <a-pagination
              show-quick-jumper
              :current="provider.current"
              :defaultPageSize="provider.defaultPageSize"
              :total="count"
              @change="onChange"
            />
          </div>
          </div>
        </Collection>
      </section>
    </article>
  </Page>
</template>
<script>
import Collection from '@/components/Collection'
export default {
  name: 'search',
  components: {
    Collection
  },
  data () {
    var query = {}
    query.post_title = ''
    var indextab = this.$route.query.type
    var keyword = this.$route.query.keyword
    if (!indextab) {
      indextab = 1
    }
    query.post_title = keyword
    query.provider_name = keyword
    query.person_name = keyword
    query.recase_title = keyword
    query.law_title = keyword
    return {
      indextab: indextab,
      modeldata: 'post',
      provider: {
        current: 1,
        defaultPageSize: 12,
        limit: 1
      },
      query: query,
      keyword: keyword
    }
  },
  methods: {
    onChange (page, pageSize) {
      this.provider.current = this.provider.limit = page
    },
    indextabfun (index) {
      if (index === 1) {
        this.modeldata = 'post'
      } else if (index === 2) {
        this.modeldata = 'provider'
      } else if (index === 3) {
        this.modeldata = 'person'
      } else if (index === 4) {
        this.modeldata = 'case'
      } else if (index === 5) {
        this.modeldata = 'law'
      } else {
        this.modeldata = 'post'
      }
      this.indextab = index
      this.keyword = ''
      this.query.post_title = ''
      this.query.provider_name = ''
      this.query.person_name = ''
      this.query.recase_title = ''
      this.query.law_title = ''
    },
    search () {
      if (this.keyword.length > 0) {
        this.query.post_title = this.keyword
        this.query.provider_name = this.keyword
        this.query.person_name = this.keyword
        this.query.recase_title = this.keyword
        this.query.law_title = this.keyword
        if (this.indextab === 2) {
          this.query.person_name = ''
        }
        if (this.indextab === 3) {
          this.query.provider_name = ''
        }
      } else {
        this.query.post_title = ''
        this.query.provider_name = ''
        this.query.person_name = ''
        this.query.recase_title = ''
        this.query.law_title = ''
      }
    }
  }
}
</script>
<style scoped src="../assets/css/search.css"></style>
